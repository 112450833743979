<template>
    <div>
        <!-- Page Title Section -->
        <section class="page-title">
            <div class="pattern-layer-one" :style="{backgroundImage: 'url('+bgPattern5+')'}"></div>
            <div class="pattern-layer-two" :style="{backgroundImage: 'url('+bgPattern6+')'}"></div>
            <div class="icon-layer-one" :style="{backgroundImage: 'url('+icon5+')'}"></div>
            <div class="icon-layer-two" :style="{backgroundImage: 'url('+icon5+')'}"></div>
            <div class="icon-layer-three" :style="{backgroundImage: 'url('+icon6+')'}"></div>
            <div class="icon-layer-four" :style="{backgroundImage: 'url('+iconPattern1+')'}"></div>
            <div class="icon-layer-five" :style="{backgroundImage: 'url('+iconPattern2+')'}"></div>
            <div class="auto-container">
                <h1>FAQs</h1>
                <ul class="page-breadcrumb">
                    <li>
                        <router-link :to="{name: 'Home'}">home</router-link>
                    </li>
                    <li>Our FAQs</li>
                </ul>
            </div>
        </section>
        <!-- End Page Title Section -->

        <!-- Faq Page Section -->
        <section class="faq-page-section">
            <div class="auto-container">

                <!-- Sec Title -->
                <div class="sec-title centered">
                    <div class="title">Need Help?</div>
                    <h2>Frequently Asked Questions.</h2>
                </div>

                <!-- Accordion Box -->
                <div class="accordion-boxed">
                    <div class="inner-box">
                        <div class="row clearfix">

                            <!-- Image Column -->
                            <div class="image-column col-lg-5 col-md-12 col-sm-12">
                                <div class="inner-column">
                                    <div class="image">
                                        <img src="../../assets/images/resource/faq-1.jpg" alt="" />
                                    </div>
                                </div>
                            </div>

                            <!-- Content Column -->
                            <div class="content-column col-lg-7 col-md-12 col-sm-12">
                                <div class="inner-column">
                                    <h4>KickStart Solutions.</h4>

                                    <!-- Accordian Box -->
                                    <ul class="accordion-box">

                                        <!-- Block -->
                                        <li class="accordion block active-block">
                                            <div class="acc-btn active"><div class="icon-outer"><span class="icon icon-plus fa fa-plus"></span> <span class="icon icon-minus fa fa-minus"></span></div>01. Who are we?</div>
                                            <div class="acc-content current">
                                                <div class="content">
                                                    <div class="accordian-text">
                                                        At TrustEarn we provide a full investment service focused on the bitcoin and cryptocurrency market We are among the best platforms to invest and grow your bitcoin and other cryptocurrency
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                        <!-- Block -->
                                        <li class="accordion block">
                                            <div class="acc-btn"><div class="icon-outer"><span class="icon icon-plus fa fa-plus"></span> <span class="icon icon-minus fa fa-minus"></span></div>02. How do i create an account?</div>
                                            <div class="acc-content">
                                                <div class="content">
                                                    <div class="accordian-text">
                                                        Registration process is very easy and will take a few moments to complete Simply click Register button and fill in all the required fields
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                        <!-- Block -->
                                        <li class="accordion block">
                                            <div class="acc-btn"><div class="icon-outer"><span class="icon icon-plus fa fa-plus"></span> <span class="icon icon-minus fa fa-minus"></span></div>03. How do i make a deposit?</div>
                                            <div class="acc-content">
                                                <div class="content">
                                                    <div class="accordian-text">
                                                        To deposit funds in your trading account is quick and simple
                                                        For your convenience you may choose one of the several available
                                                        deposit methods To make a successful deposit login into your dashboard
                                                        and click on the DEPOSIT button, enter the required amount for your selected package
                                                        and follow the outlined steps to complete your transaction
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                        <!-- Block -->
                                        <li class="accordion block">
                                            <div class="acc-btn"><div class="icon-outer"><span class="icon icon-plus fa fa-plus"></span> <span class="icon icon-minus fa fa-minus"></span></div>04. How long does my deposit take before it can reflect on my TrustEarn account dashboard?</div>
                                            <div class="acc-content">
                                                <div class="content">
                                                    <div class="accordian-text">
                                                        Your deposit will be reflected immediately once it is confirmed on the blockchain network
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                    </ul>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <!-- End Accordion Box -->

                <!-- Accordion Box -->
                <div class="accordion-boxed style-two">
                    <div class="inner-box">
                        <div class="row clearfix">

                            <!-- Content Column -->
                            <div class="content-column col-lg-7 col-md-12 col-sm-12">
                                <div class="inner-column">
                                    <h4>The Need to Knows.</h4>

                                    <!-- Accordian Box -->
                                    <ul class="accordion-box">

                                        <!-- Block -->
                                        <li class="accordion block active-block">
                                            <div class="acc-btn active"><div class="icon-outer"><span class="icon icon-plus fa fa-plus"></span> <span class="icon icon-minus fa fa-minus"></span></div>01. How do i make a withdrawal?</div>
                                            <div class="acc-content current">
                                                <div class="content">
                                                    <div class="accordian-text">
                                                        To make a withdrawal request click the WITHDRAW button in your account dashboard and input the required details to withdraw
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                        <!-- Block -->
                                        <li class="accordion block">
                                            <div class="acc-btn"><div class="icon-outer"><span class="icon icon-plus fa fa-plus"></span> <span class="icon icon-minus fa fa-minus"></span></div>02. How long does it take to process my withdrawal?</div>
                                            <div class="acc-content">
                                                <div class="content">
                                                    <div class="accordian-text">
                                                        Once we receive your withdrawal request we process immediately and send to your bitcoin wallet
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                        <!-- Block -->
                                        <li class="accordion block">
                                            <div class="acc-btn"><div class="icon-outer"><span class="icon icon-plus fa fa-plus"></span> <span class="icon icon-minus fa fa-minus"></span></div>03. Is this company properly registered?</div>
                                            <div class="acc-content">
                                                <div class="content">
                                                    <div class="accordian-text">
                                                        Yes we are officially and properly registered with the united kingdom company house our company registration number is R2335443 and registered with the name TrustEarn Investment LTD
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                        <!-- Block -->
                                        <li class="accordion block">
                                            <div class="acc-btn"><div class="icon-outer"><span class="icon icon-plus fa fa-plus"></span> <span class="icon icon-minus fa fa-minus"></span></div>04. How many times can i make a deposit?</div>
                                            <div class="acc-content">
                                                <div class="content">
                                                    <div class="accordian-text">
                                                        Yes you can make as many deposit as you want on any of our investment plans except the starter plan where you can only invest 3 times after which you make a choice to continue investing with us or not
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                    </ul>

                                </div>
                            </div>

                            <!-- Image Column -->
                            <div class="image-column col-lg-5 col-md-12 col-sm-12">
                                <div class="inner-column">
                                    <div class="image">
                                        <img src="../../assets/images/resource/faq-2.jpg" alt="" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <!-- End Accordion Box -->

            </div>
        </section>
        <!-- End Faq Page Section -->
    </div>
</template>

<script>
import icon1 from "../../assets/images/icons/icon-1.png";
import icon2 from "../../assets/images/icons/icon-2.png";
import icon3 from "../../assets/images/icons/icon-3.png";
import icon4 from "../../assets/images/icons/icon-4.png";
import bgPattern5 from "../../assets/images/background/pattern-5.png";
import bgPattern6 from "../../assets/images/background/pattern-6.png";
import icon5 from "../../assets/images/icons/icon-5.png";
import icon6 from "../../assets/images/icons/icon-6.png";
import iconPattern1 from "../../assets/images/icons/pattern-1.png";
import iconPattern2 from "../../assets/images/icons/pattern-2.png";


export default {
    name: "Faq",
    data(){
        return {
            icon1, icon2, icon3, icon4,
            bgPattern5, bgPattern6,
            icon5, icon6, iconPattern1,
            iconPattern2
        }
    },
    mounted() {
        if($('.accordion-box, .accordion-box-two').length){
            $(".accordion-box, .accordion-box-two").on('click', '.acc-btn', function() {

                var outerBox = $(this).parents('.accordion-box, .accordion-box-two');
                var target = $(this).parents('.accordion');

                if($(this).hasClass('active')!==true){
                    $(outerBox).find('.accordion .acc-btn').removeClass('active');
                }

                if ($(this).next('.acc-content').is(':visible')){
                    return false;
                }else{
                    $(this).addClass('active');
                    $(outerBox).children('.accordion').removeClass('active-block');
                    $(outerBox).find('.accordion').children('.acc-content').slideUp(300);
                    target.addClass('active-block');
                    $(this).next('.acc-content').slideDown(300);
                }
            });
        }
    }
}
</script>

<style scoped>

</style>